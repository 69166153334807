@import "../../Common.module.scss";

.circle {
  border: 4px solid #59B5DB;
  border-radius: 50%;
  width: 175px;
  height: 175px;
  text-align: center;

  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 55px;
    font-weight: 300;
    color: #59B5DB;
    margin: 0;
    line-height: 1.1;
  }
;

  p {
    font-size: 14px;
    font-weight: 500;
    color: #59B5DB;
    margin: 0;
  }
;

  @media (max-width: $medium-screen) {
    width: 128px;
    height: 128px;
    h3 {
      font-size: 40px;
    };
    p {
      font-size: 10px;
    };
  }
  @media (max-width: $small-screen) {
    width: 96px;
    height: 96px;
    border: 2px solid #59B5DB;
    h3 {
      font-size: 30px;
    };
    p {
      font-size: 8px;
    };
  }
  @media (max-width: $extra-small-screen) {
    width: 54px;
    height: 54px;
    h3 {
      font-size: 16px;
    };
    p {
      font-size: 4.5px;
    };
  }
  @media (max-width: $mobile-screen) {
    width: 120px;
    height: 120px;
    border: 3px solid #59B5DB;

    h3 {
      font-size: 35px;
    };
    p {
      font-size: 8px;
    };
  }
  @media (max-width: $small-mobile-screen) {
    width: 104px;
    height: 104px;

    h3 {
      font-size: 34px;
    };
    p {
      font-size: 7px;
    };
  }
}

.main {
  background: linear-gradient(167.33deg, #3F566C -13.56%, #5D384B 61.89%, #290A1D 129.07%);
  position: relative;
  overflow: hidden;
  .main_videoDesktop {
    z-index: 0;
    object-fit: cover;
    display: block;
    width: 100%;
    @media (max-width: $mobile-screen) {
      display: none;
    }
  }
  .main_videoMobile{
    //z-index: 0;
    object-fit: cover;
    width: 100%;
    min-width: 480px;
    margin-left: 50%;
    transform: translateX(-50%);
    display: none;
    @media (max-width: $mobile-screen) {
      display: block;
    }
    @media (max-width: $small-mobile-screen) {
      min-width: 400px;
    }
  }

  .main_wrapper {
    @extend .wrapper;

    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    top: calc(35% + 31px);
    transform: translateY(-35%);

    display: flex;

    .main_content {
      color: #fff;
      text-align: right;

      max-width: 52%;

      .main_content__header {
        font-weight: 500;
        font-size: 47px;
        margin-bottom: 54px;

        @media (max-width: $medium-screen) {
          margin-bottom: 40px;
          font-size: 32px;
        }
        @media (max-width: $small-screen) {
          margin-bottom: 30px;
          font-size: 23px;
        }
        @media (max-width: $extra-small-screen) {
          margin-bottom: 17px;
          font-size: 16px;
        }
        @media (max-width: $extra-small-screen) {
          font-size: 16px;
        }
      }
      .main_content__p {
        font-weight: 300;
        font-size: 28px;
        margin-bottom: 78px;
        @media (max-width: $medium-screen) {
          margin-bottom: 58px;
          font-size: 20px;
        }
        @media (max-width: $small-screen) {
          margin-bottom: 44px;
          font-size: 15px;
        }
        @media (max-width: $extra-small-screen) {
          margin-bottom: 25px;
          font-size: 9px;
        }
      }
      .main_content__images {
        display: flex;
        justify-content: end;

        .circle {
          &:not(:last-child) {
            margin-right: 64px;
          }
        }
        img {
          width: 171px;
        }
        img:not(:last-child)  {
          margin-right: 64px;
        }
        //@media (max-width: $medium-screen) {
        //  img {
        //    width: 125px;
        //  }
        //  img:not(:last-child)  {
        //    margin-right: 47px;
        //  }
        //}
        //@media (max-width: $small-screen) {
        //  img {
        //    width: 95px;
        //  }
        //  img:not(:last-child)  {
        //    margin-right: 35px;
        //  }
        //}
        //@media (max-width: $extra-small-screen) {
        //  img {
        //    width: 53px;
        //  }
        //  img:not(:last-child)  {
        //    margin-right: 20px;
        //  }
        //}

        @media (max-width: $medium-screen) {
          .circle {
            &:not(:last-child) {
              margin-right: 47px;
            }
          }
        }
        @media (max-width: $small-screen) {
          .circle {
            &:not(:last-child) {
              margin-right: 35px;
            }
          }
        }
        @media (max-width: $extra-small-screen) {
          .circle {
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }

      }

      //@media (max-width: $extra-small-screen) {
      //  max-width: 50%;
      //}
    }

    @media (max-width: $medium-screen) {
      top: calc(43% + 31px);
      transform: translateY(-43%);
    }

    @media (max-width: $extra-small-screen) {
      max-width: 500px;
      padding: 0;
    }
    @media (max-width: $mobile-screen) {
      top: calc(50% + 155px);
      left: 50%;
      transform: translateX(-50%);
      display: block;
      padding: 0 7px;

      .main_content {
        margin: 0 auto;
        text-align: center;
        max-width: unset;
        padding: 0 15px;

        .main_content__header {
          font-size: 1.8em;
          margin-bottom: 20px;
        }

        .main_content__p {
          font-size: 1.2em;
          margin-top: 0;
          margin-bottom: 35px;
        }

        .main_content__images {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;

          .circle {
            &:nth-child(n) {
              margin: 0;
            }
          }

          img {
            width: 100%;
            max-width: 120px;
            &:nth-child(n) {
              margin: 0;
            }
          }
        }
      }
    }

    @media (max-width: $small-mobile-screen) {
      top: calc(50% + 125px);
      .main_content {
        padding: 0 10px;
        .main_content__header {
          font-size: 1.5em;
          margin-bottom: 20px;
        }

        .main_content__p {
          font-size: 0.9em;
          margin-bottom: 25px;
        }

        .main_content__images {
          img {
            width: 100%;
            max-width: 100px;
          }
        }
      }
    }
  }
}

br.mobile {
  display: none;
}
@media screen and (max-width: $mobile-screen) {
  br.mobile {
    display: block;
  }
}