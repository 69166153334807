@import '../Common.module.scss';

.header {
  background: linear-gradient(#484f64, #573948 90%);
  // background: linear-gradient(#3f566c -13.56%, #5d384b 61.89%, #290a1d 129.07%);
  position: relative;
  overflow: hidden;
  height: 1000px;

  @media (max-width: $medium-screen) {
    height: 700px;
  }
  @media (max-width: $small-screen) {
    height: 1200px;
  }
  @media (max-width: $mobile-screen) {
    height: 800px;
  }
  @media (max-width: $small-mobile-screen) {
    height: 600px;
  }

  .header_wrapper {
    @extend .wrapper;

    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    top: calc(35% + 31px);
    transform: translateY(-25%);

    display: flex;
    flex-direction: column;

    // @media (max-width: $small-screen) {
    //   transform: translateY(-32%);
    //   flex-direction: column-reverse;
    //   gap: 24px;
    // }
    // @media (max-width: $extra-small-screen) {
    //   max-width: 100%;
    // }
    @media (max-width: $small-screen) {
      transform: translateY(-32%);
    }

    .header_main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
      color: #fff;

      @media (max-width: $medium-screen) {
        gap: 40px;
      }
      @media (max-width: $small-screen) {
        flex-direction: column;
        text-align: center;
      }
    }
    .header_text {
      // max-width: 50%;

      // @media (max-width: $small-screen) {
      //   max-width: 100%;
      //   // margin-top: 275px;
      // }

      h1 {
        font-weight: 400;
        font-size: 50px;
        margin: 0;

        @media (max-width: $medium-screen) {
          font-size: 40px;
        }
        @media (max-width: $small-screen) {
          font-size: 50px;
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 30px;
        }
      }
      p {
        font-weight: 400;
        font-size: 22px;
        margin: 40px 0px;
        white-space: nowrap;

        // &:first-of-type {
        //   margin-top: 32px;
        // }
        // &:last-of-type {
        //   margin-bottom: 64px;
        // }

        @media (max-width: $medium-screen) {
          font-size: 18px;
          margin: 30px 0px;
        }
        @media (max-width: $small-screen) {
          font-size: 25px;
          margin: 20px 0px;
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 20px;
        }
      }

      .btn {
        user-select: none;
        border-radius: 999px;
        background-color: #7dbada;
        color: #000;
        text-decoration: none;
        padding: 10px 30px;
        font-size: 18px;
        transition: all 300ms;
        width: fit-content;
        margin: 0 auto;
        display: block;

        &:hover {
          // color: #fff;
          // background-color: #5d384b;
          // box-shadow: 0px 0px 0px 3px #fff;
          transform: scale(1.1);
        }

        @media (max-width: $medium-screen) {
          font-size: 20px;
        }
        @media (max-width: $small-screen) {
          font-size: 25px;
        }
        @media (max-width: $mobile-screen) {
          font-size: 25px;
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 20px;
        }
      }
    }

    .f_video {
      border-radius: 8px;
      width: 45%;
      margin-right: 75px;

      @media (max-width: $medium-screen) {
        margin-right: 0px;
      }
      @media (max-width: $small-screen) {
        width: 100%;
      }
    }
    .header_video {
      border: 0px;
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 8px;

      @media (max-width: $small-screen) {
        margin-bottom: 40px;
      }
    }
    .header_img {
      width: 300px;
      align-self: end;
      margin-top: 40px;

      @media (max-width: $medium-screen) {
        width: 250px;
      }
    }
  }
}

.section2 {
  text-align: center;
  padding-top: 64px;
  padding-bottom: 128px;
  color: #fff;
  background: linear-gradient(to top, #484f64, #573948 90%);

  h1 {
    font-weight: 400;
    margin: 0;
    letter-spacing: 7px;
    font-size: 35px;

    @media (max-width: $medium-screen) {
      font-size: 30px;
    }
    @media (max-width: $small-screen) {
      font-size: 20px;
    }
  }
  .sep {
    position: relative;
    padding: 60px 0px;

    @media (max-width: $small-screen) {
      padding: 30px 0px;
    }
  }
  .sep::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100vw;
    transform: translateX(-50%);
    background-color: #fff;
  }

  .card_container_d {
    display: grid;
    grid-template-columns: 1.75fr 0.5fr 1.75fr 0.5fr 1.75fr; // 69 xdd
    place-items: center;
    // gap: 30px;

    // @media (max-width: $small-screen) {
    //   flex-direction: column;
    //   gap: 32px;
    // }
    @media (max-width: $small-screen) {
      display: none;
    }

    img {
      width: 100%;
      padding: 22px;
    }
    .card_img {
      padding: 30px;
    }
    h3 {
      font-weight: 400;
      font-size: 30px;
      // margin: 0;
      margin: 20px 0px;
      // margin-top: 40px;
      // white-space: nowrap;
    }
    p {
      font-size: 16px;
      margin: 0px;
      place-self: start;
    }
  }
  .card_container_m {
    display: none;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $small-screen) {
      display: flex;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    img {
      width: 40%;
    }
    h3 {
      font-weight: 400;
      font-size: 35px;
      margin: 0px;
    }
    p {
      font-size: 20px;
      margin: 0px;
      width: 75%;
    }
  }
}

.section3 {
  background: linear-gradient(#484f64, #573948 90%);
  color: #fff;
  padding-top: 64px;
  padding-bottom: 128px;

  .botHeader {
    display: flex;
    gap: 30px;
    margin-bottom: 50px;

    @media (max-width: $medium-screen) {
      gap: 20px;
      margin-bottom: 10px;
    }

    h1 {
      font-weight: 500;
      letter-spacing: 3px;
      font-size: 50px;

      @media (max-width: $medium-screen) {
        font-size: 40px;
      }
    }
    img {
      width: 75px;
      object-fit: contain;
      margin-top: 40px;

      @media (max-width: $medium-screen) {
        width: 50px;
        margin-top: 20px;
      }
    }
  }

  .botImgs {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: $medium-screen) {
      gap: 30px;
    }

    img {
      flex-grow: 1;
      max-width: 100%;
      height: auto;
    }
  }
}
