@import "../../Common.module.scss";

.pricingPage {
  font-family: AvenirNext, Arial, sans-serif;
  padding: 24px;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1023px) {
    justify-content: flex-start;
    padding: 16px;
  }

  .pricingPage_plansCards {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    max-width: 1440px;
    gap: 12px;

    @media (max-width: 1023px) {
      grid-template-columns: 1fr 1fr;
      max-width: 780px;
    }
    @media (max-width: 540px) {
      grid-template-columns: 1fr;
      max-width: 100%;
    }

    .planCard {
      border: 2px solid #000;
      border-radius: 16px;
      overflow: hidden;
      padding: 16px;
      display: grid;
      grid-template-rows: auto auto 1fr 2fr auto;
      gap: 16px;

      @media (min-width: 1270px) {
        padding: 24px;
      }

      h1 {
        text-align: center;
        background: linear-gradient(167.33deg, #3F566C -13.56%, #5D384B 61.89%, #290A1D 129.07%);
        padding: 16px;
        margin: -16px;
        margin-bottom: 0px;
        font-size: 30px;
        font-weight: 500;
        color: #fff;

        @media (min-width: 1270px) {
          padding: 24px;
          margin: -24px;
          margin-bottom: 0px;
          font-size: 36px;
        }
      }
      h4 {
        font-size: 22px;
        text-align: center;
        margin: 0;

        @media (min-width: 1270px) {
          font-size: 28px;
        }
      }
      p {
        font-size: 18px;
        margin: 0;
        font-weight: 500;

        @media (min-width: 1270px) {
          font-size: 20px;
        }
      }
      ul {
        padding-left: 16px;
        margin: 0;

        li {
          font-weight: 500;
          font-style: italic;
          font-size: 16px;
          line-height: 1.5;

          @media (min-width: 1270px) {
            font-size: 18px;
          }
        }
      }
      a {
        border-radius: 8px;
        color: #000;
        background: #59B5DB;
        padding: 8px 16px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;
        font-family: AvenirNext, Arial, sans-serif;

        &:hover {
          cursor: pointer;
          background: rgba(89, 181, 219, 0.64);
          transition: all 0.5s ease;
        }

        @media (min-width: 1270px) {
          font-size: 20px;
        }
      }

      position: relative;
      .disclaimer {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        position: absolute;
        bottom: 8px;
        right: 8px;
        // white-space: nowrap;
        margin: 0;
      }
      .disclaimer2::after {
        content: 'Trial is available to first-time users only *';
        display: block;
        text-align: end;
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
        margin-top: 4px;
      }
    }
  }
  .pricingPage_p {
    text-align: center;
    font-size: 16px;

    @media (min-width: 1270px) {
      font-size: 18px;
    }
  }
}