@import "../../Common.module.scss";

.contactUs {
  box-sizing: border-box;
  background: #fff;
  text-align: center;
  overflow: hidden;
  padding: 60px 0;

  .contactUs_wrapper {
    @extend .wrapper;
    padding: 0;

    h2 {
      color: #231F20;
    }

    p {
      font-size: 20px;
      line-height: 141%;
      letter-spacing: 1px;

      @media screen and (max-width: $small-screen) {
        font-size: 16px;
      }
    }

    .contactUs_form {
      box-sizing: border-box;
      margin: auto;
      margin-top: 40px;
      //max-width: 1020px;

      p {
        text-align: right;
        font-size: 14px;
        margin-bottom: 10px;
        margin-right: 5%;
      }

      .hidden {
        display: none;
      }
      .invalid {
        border-color: #f00;
      }

      select, textarea, input {
        margin: auto;
        padding: 15px 20px;
        border: 1.5px solid #ccc;
        display: block;
        resize: none;
        width: 90%;
        border-radius: 0;
        margin-bottom: 20px;
        -webkit-appearance: none;
        font-size: 20px;
        line-height: 1em;
        text-align: left;
      }

      & *::placeholder {
        color: #ccc;
      }

      select {
        color: #000;
        background: center right 0.75rem no-repeat url("../../images/dropdown.svg");

        &:invalid {
          color: #ccc !important;
        }
      }

      option[value=""][disabled] {
        display: none;
      }

      textarea {
        min-height: 169px;
        font-family: AvenirNext, Arial, sans-serif;
        font-weight: 400;
        font-size: 22px;
      }

      button {
        margin: auto;
        color: #fff;
        padding: 15px 35px;
        background-color: #000;
        cursor: pointer;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 300;
        border-radius: 0;
        display: inline-block;
        -webkit-appearance: none;
        -webkit-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;

        &:hover {
          opacity: 0.7;
        }
      }

      .success {
        pointer-events: none;
        background-color: #060;
      }
    }
  }


  @media (max-width: $medium-screen) {
    font-size: 58px;
  }
  @media (max-width: $small-screen) {
    font-size: 44px;
  }
  @media (max-width: $extra-small-screen) {
    font-size: 32px;
  }
  @media (max-width: $small-mobile-screen) {
    .contactUs_form {
      select, textarea, input {
        margin: auto;
        max-width: 360px;
      }
    }
  }
}