@import "../../Common.module.scss";

.nav {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-top: 65px;

  .nav_wrapper {
    @extend .wrapper;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav_logo {
      width: 193px;
      height: auto;
      z-index: 9999;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: $small-screen) {
        width: 145px;
      }
    }

    .btn {
      border: 0;
      padding: 0;
      background: transparent;
      cursor: pointer;
      line-height: 1;
      color: inherit;
      font-size: 20px;
    }

    .burger {
      z-index: 3;
      right: 0;
      display: grid;
      place-items: center;
      width: 26px;
      height: 26px;
      background-image: url("../../images/navbar/menu.svg");
      background-repeat: no-repeat;
      background-position: center;

      order: 3;
      @media (width > 780px) {
        display: none;
      }
    }

    .burger_opened {
      background-image: url("../../images/navbar/close.svg");
    }

    .nav_links {
      .nav_links__list {
        margin: 0;
        padding: 0;

        .nav_links__list___link {
          display: inline-block;

          &:nth-child(n+2) {
            margin-left: 45px;
          }

          a {
            text-decoration: none;
            color: #fff;
            font-size: 23px;
            font-weight: 400;
            transition: all 0.25s ease;

            &:hover {
              opacity: 0.7;
            }
          }

          @media (max-width: $medium-screen) {
            &:nth-child(n+2) {
              margin-left: 33px;
            }
            a {
              font-size: 17px;
            }
          }
          @media (max-width: $small-screen) {
            &:nth-child(n+2) {
              margin-left: 26px;
            }
            a {
              font-size: 13px;
            }
          }
          @media (max-width: $extra-small-screen) {
            &:nth-child(n+2) {
              margin-left: 0;
            }
          }
        }
      }
    }

    .nav_links__btn {
      background: #7DBADA;
      color: #231F20;
      padding: 8px 19px;
      border: none;
      border-radius: 45px;

      a:nth-child(2) {
        display: none;
      }

      //margin-left: 73px;
      margin-left: -13%;

      font-size: 18px;
      font-weight: 600;

      a {
        text-decoration: none;
        color: #231F20;
      }

      transition: transform 0.2s ease-in-out;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }

      @media (max-width: $medium-screen) {
        font-size: 14px;
        margin-left: -10%;
      }
      @media (max-width: $small-screen) {
        font-size: 12px;
        margin-left: -5%;
      }
      @media (max-width: $extra-small-screen) {
        order: 2;
        margin-left: 9%;

        a:nth-child(1) {
          display: none;
        }
        a:nth-child(2) {
          display: block;
        }
      }
    }

  }

  @media (max-width: $medium-screen) {
    padding-top: 48px;
  }
  @media (max-width: $small-screen) {
    padding-top: 36px;
  }
  @media (max-width: $extra-small-screen) {
    //padding-top: 0;
    padding: 17px 5px;
    //.nav_wrapper {
    //  padding: 17px 5px;
      .box {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.25s ease;

        .nav_links {
          display: flex;
          flex-direction: column;
          align-items: center;

          .nav_links__list {
            display: block;
            position: relative;
            right: unset;
            top: unset;
            transform: unset;

            .nav_links__list___link {
              display: block;
              margin: 0;
              padding: 0;
              text-align: center;

              a {
                margin: 0;
                display: inline-block;
                padding: 10px;
              }
            }
          }

        }
      }
    }
  }
//}


.navbar_active {
  .box {
    visibility: visible;
    opacity: 1;
  }
}
