@import "../../Common.module.scss";

.section5 {
  background: linear-gradient(165.65deg, #3F566C -102.96%, #5D384B 10.34%, #231F20 99.28%);
  padding: 96px 0px;
  text-align: center;

  .section5_wrapper {
    @extend .wrapper;

    .section5_clients {
      margin-bottom: 7%;

      .section5_clients__images {
        margin: 30px auto;
        margin-top: 50px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 5%;
        align-items: center;

        .gridImg {
          margin: 0 auto;
          max-width: 55%;
          height: auto;

          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(9) {
            max-width: 45%;
          }
          &:nth-child(4) {
            max-width: 30%;
          }
          &:nth-child(9) {
            max-width: 25%;
          }
        }
      }
    }

    .section5_partners {
      .section5_partners__images {
        margin: 37px auto;
        display: inline-grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 7%;
        align-items: center;
        justify-content: center;

        a {
          margin: auto;

          &:nth-child(n + 2) {
            width: 80%;
            justify-self: end;
          }
          &:nth-child(1) img {
            max-width: 200px;
          }
          &:nth-child(2) img {
            max-width: 350px;
          }
          &:nth-child(3) img {
            max-width: 115px;
          }

          img {
            max-width: 250px;
            width: 100%;
            height: auto;
            place-self: center;
            margin: auto;

            &:hover {
              transition: transform 0.2s ease-in-out;
              transform: scale(1.1);
              cursor: pointer;
            }
          }
        }
      }
      p {
        font-weight: 300;
        font-size: 24px;
        color: #fff;
        margin: 0;

        a {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: $medium-screen) {
    .section5_wrapper {
      .section5_partners {
        p {
          font-size: 18px;
        }

        .section5_partners__images {
          a {
            img {
              max-width: 230px;
            }
          }
        }
      }
    }
  }
  @media (max-width: $small-screen) {
    .section5_wrapper {
      .section5_partners {
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: $extra-small-screen) {
    .section5_wrapper {
      .section5_partners {
        p {
          font-size: 12px;
        }

        .section5_partners__images {
          a {
            img {
              max-width: 170px;
              width: 100%;
            }
          }
        }
      }
    }
  }
  @media (max-width: $mobile-screen) {
    .section5_wrapper {
      .section5_partners {
        .section5_partners__images {
          a {
            img {
              max-width: 110px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}