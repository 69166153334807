@import '../Common.module.scss';

.header {
  background: linear-gradient(167.33deg, #3f566c -13.56%, #5d384b 61.89%, #290a1d 129.07%);
  position: relative;
  overflow: hidden;
  height: 2650px;

  @media (max-width: $medium-screen) {
    height: 2450px;
  }
  @media (max-width: 1300px) {
    height: 2550px;
  }
  @media (max-width: 1200px) {
    height: 2650px;
  }
  @media (max-width: 1100px) {
    height: 2850px;
  }
  @media (max-width: $small-screen) {
    height: 2100px;
  }
  @media (max-width: 850px) {
    height: 2300px;
  }
  @media (max-width: 700px) {
    height: 2500px;
  }
  @media (max-width: 650px) {
    height: 2600px;
  }
  @media (max-width: $mobile-screen) {
    height: 2700px;
  }
  @media (max-width: 550px) {
    height: 2800px;
  }
  @media (max-width: 500px) {
    height: 2900px;
  }
  @media (max-width: 475px) {
    height: 3000px;
  }
  @media (max-width: 450px) {
    height: 3300px;
  }
  @media (max-width: $small-mobile-screen) {
    height: 3500px;
  }

  .header_wrapper {
    @extend .wrapper;

    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    top: calc(35% + 31px);
    transform: translateY(-33%);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    color: #fff;

    @media (max-width: $medium-screen) {
      max-width: 1443px;
    }
    @media (max-width: $small-screen) {
      transform: translateY(-34%);
      flex-direction: column-reverse;
      gap: 24px;
    }

    .header_text {
      max-width: 75%;
      text-align: left;

      @media (max-width: $small-screen) {
        max-width: 90%;
      }

      .question {
        padding: 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      h1 {
        font-weight: 500;
        font-size: 47px;
        margin: 0px;
        margin-top: 32px;

        @media (max-width: $medium-screen) {
          font-size: 32px;
        }
        @media (max-width: $small-screen) {
          font-size: 28px;
        }
        @media (max-width: $extra-small-screen) {
          font-size: 24px;
        }
      }
      h2 {
        font-weight: 500;
        font-size: 28px;
        margin: 0px;

        @media (max-width: $medium-screen) {
          font-size: 22px;
        }
        @media (max-width: $small-screen) {
          font-size: 18px;
        }
      }
      p {
        font-weight: 300;
        font-size: 20px;
        margin: 0px;

        @media (max-width: $medium-screen) {
          font-size: 20px;
        }
        @media (max-width: $small-screen) {
          font-size: 16px;
        }
      }
      a {
        color: #7dbada;
      }
    }
  }
}
