@import "../../Common.module.scss";

a {
  color: #000;
}
.showMobile {
  display: none;
}

.section4 {
  padding-top: 140px;

  .section4_inFrame {
    position: relative;
    margin: 0;
    max-width: 775px;

    .section4_inFrame__vid {
      position: absolute;
      left: 0;
      z-index: 5;
      top: 37px;

      width: 702px;

      border-top-right-radius: 1.5vw;
      border-bottom-right-radius: 1.5vw;
    }

    .section4_inFrame__frame {
      position: absolute;
      left: -185px;
      z-index: 1;

      width: 956px;
      padding-bottom: 100px;
    }

    @media (max-width: $medium-screen) {
      .section4_inFrame__vid {
        width: 528px;
        top: 28px;
      }
      .section4_inFrame__frame {
        width: 719px;
        left: -139px;
        padding-bottom: 75px;
      }
    }

    @media (max-width: $small-screen) {
      .section4_inFrame__vid {
        width: 397px;
        top: 21px;
      }
      .section4_inFrame__frame {
        width: 540px;
        left: -105px;
        padding-bottom: 56px;
      }
    }

    @media (max-width: $extra-small-screen) {
      .section4_inFrame__vid {
        width: 480px;
        top: 375px;
      }
      .section4_inFrame__frame {
        top: 350px;
        width: 654px;
        left: -126px;
        padding-bottom: 56px;
      }
    }

    @media (min-width: 330px) and (max-width: 530px) {
      .section4_inFrame__vid {
        width: 348px;
        top: 400px;
      }
      .section4_inFrame__frame {
        top: 383px;
        width: 473px;
        left: -90px;
        padding-bottom: 0;
      }
    }
  }

  .section4_wrapper {
    @extend .wrapper;
    padding-top: 70px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;

    padding-bottom: 250px;

    .section4_content {
      max-width: 690px;
      padding-right: 19px;

      h2 {
        color: #231F20;
      }

      p {
        font-weight: 300;
        font-size: 28px;
        text-align: justify;
      }

      .separator {
        width: 50%;
        height: 1px;
        background-color: #ccc;
        position: relative;
        left: 25%;
        margin: 90px 0px;
      }
      .special {
        font-weight: 500;
        text-align: center;
        font-size: 19px;
      }
    }
  }

  @media (max-width: $medium-screen) {
    padding-top: 105px;
    .section4_wrapper {
      margin-top: 40px;
      .section4_content {
        max-width: 478px;
        p {
          font-size: 20px;
        }
        .separator {
          margin: 60px 0px;
        }
        .special {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width: 1150px) and (max-width: 1340px) {
    .section4_wrapper {
      margin-right: 0;
      padding-right: 50px;
    }
  }

  @media (min-width: 1000px) and (max-width: 1150px) {
    .section4_wrapper {
      margin-right: 0;
      padding-right: 15px;

      .section4_content {
        max-width: 398px;
        // p {
        //   font-size: 14px;
        // }
      }
    }
  }

  @media (max-width: $small-screen) {
    padding-top: 79px;
    .section4_wrapper {
      margin-top: 0;
      padding-top: 67px;
      padding-bottom: 195px;

      .section4_content {
        max-width: 360px;
        p {
          font-size: 16px;
        }
        .separator {
          margin: 20px 0px;
        }
        .special {
          font-size: 13px;
        }
      }
    }
    br.showMobile {
      display: block;
    }
  }

  @media (min-width: 780px) and (max-width: 880px) {
    .section4_wrapper {
      margin-right: 0;
      padding-right: 41px;
      padding-bottom: 235px;

      .section4_content {
        max-width: 300px;

        // p {
        //   font-size: 11px;
        // }
      }
    }
  }

  @media (max-width: $extra-small-screen) {
    padding-top: 29px;
    .section4_wrapper {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 640px;
      .section4_content {
        max-width: 360px;
        // p {
        //   font-size: 16px;
        // }
      }
    }
  }

  @media (min-width: 330px) and (max-width: 530px) {
    .section4_wrapper {
      padding-bottom: 500px;
    }
  }
}

@media screen and (max-width: $mobile-screen) {
  br.show {
    display: block;
  }
}