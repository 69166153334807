// Define variables for the breakpoints
$medium-screen: 1500px;
$small-screen: 1000px;
$extra-small-screen: 780px;
$mobile-screen: 600px;
$small-mobile-screen: 395px;

.wrapper {
    max-width: 1443px;
    margin: auto;
    //padding-left: 3vw;
    //padding-right: 1vw;

    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: $medium-screen) {
        max-width: 1000px;
    }

    @media (max-width: $small-screen) {
        max-width: 750px;
    }

    @media (max-width: $extra-small-screen) {
        max-width: 400px;
    }
}