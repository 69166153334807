@import '../Common.module.scss';

.header {
  background: linear-gradient(167.33deg, #3f566c -13.56%, #5d384b 61.89%, #290a1d 129.07%);
  position: relative;
  overflow: hidden;
  // height: 50vw;
  height: 900px;

  @media (min-width: 2000px) {
    // height: 33vw;
    height: 1500px;
  }
  @media (max-width: $medium-screen) {
    // height: 1500px;
  }
  @media (max-width: $small-screen) {
    height: 1000px;
  }
  @media (max-width: $mobile-screen) {
    height: 800px;
  }
  @media (max-width: $small-mobile-screen) {
    height: 600px;
  }

  .header_wrapper {
    @extend .wrapper;

    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    top: calc(35% + 31px);
    transform: translateY(-25%);

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    color: #fff;

    @media (max-width: $small-screen) {
      transform: translateY(-32%);
      flex-direction: column-reverse;
      gap: 24px;
    }
    @media (max-width: $extra-small-screen) {
      max-width: 100%;
    }

    .header_img {
      width: 80%;
      filter: invert(100%);

      @media (max-width: $small-screen) {
        width: 60%;
        display: none;
      }
    }

    .header_text {
      max-width: 40%;
      text-align: center;

      @media (max-width: $small-screen) {
        max-width: 100%;
        // margin-top: 275px;
      }

      .h1 {
        font-weight: 500;
        font-size: 50px;

        @media (max-width: $medium-screen) {
          font-size: 40px;
        }
        @media (max-width: $small-screen) {
          font-size: 50px;
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 30px;
        }
      }
      .h3 {
        font-weight: 400;
        font-size: 25px;
        margin-top: 0px;
        margin-bottom: 0px;

        &:first-of-type {
          margin-top: 32px;
        }
        &:last-of-type {
          margin-bottom: 64px;
        }

        @media (max-width: $medium-screen) {
          font-size: 20px;
        }
        @media (max-width: $small-screen) {
          font-size: 25px;

          &:first-of-type {
            margin-top: 0px;
          }
          &:last-of-type {
            margin-bottom: 32px;
          }
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 20px;
        }
      }

      .btn {
        user-select: none;
        border-radius: 999px;
        background-color: #fff;
        color: #000;
        text-decoration: none;
        padding: 8px 40px;
        font-size: 30px;
        transition: all 300ms;
        z-index: 10;
        display: block;
        width: fit-content;
        margin: 0 auto;

        &:hover {
          color: #fff;
          background-color: #5d384b;
          box-shadow: 0px 0px 0px 3px #fff;
          // transform: scale(1.2);
        }

        @media (max-width: $medium-screen) {
          font-size: 20px;
        }
        @media (max-width: $small-screen) {
          font-size: 30px;
        }
        @media (max-width: $mobile-screen) {
          font-size: 25px;
        }
        @media (max-width: $small-mobile-screen) {
          font-size: 20px;
        }
      }
    }

    .f_video {
      border-radius: 8px;
      width: 45%;
      margin-right: 75px;

      @media (max-width: $medium-screen) {
        margin-right: 0px;
      }
      @media (max-width: $small-screen) {
        width: 100%;
      }
    }
    .header_video {
      border: 0px;
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 8px;

      @media (max-width: $small-screen) {
        margin-bottom: 40px;
      }
    }
  }
}

.section {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 64px;
  font-size: 26px;

  .h1 {
    font-weight: 500;
  }
  .h3 {
    font-weight: 400;
    font-size: 23px;
    margin: 0px;
    padding: 8px;
  }
  .p {
    font-weight: 400;
    font-size: 20px;
    margin: 0px;
    padding: 12px;
    padding-top: 0px;
    margin-top: auto;
  }
  .sep {
    height: 1px;
    width: 80%;
    border-radius: 4px;
    background-color: #000;
    margin: 8px 0px;

    &:last-of-type {
      margin-bottom: auto;
    }
  }

  .card_container {
    display: flex;
    gap: 16px;

    @media (max-width: $small-screen) {
      flex-direction: column;
      gap: 32px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 2px #000;
    transition: transform 250ms;

    &:hover {
      transform: scale(1.02);
    }

    .card_img {
      width: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.sub {
  margin-top: 30px;
}
