@import "../../Common.module.scss";

.section6 {
  //background-image: url("../../images/media_slider/media_back.png");
  //background: linear-gradient(170.45deg, rgba(63, 86, 108, 0.5) -134.06%, rgba(0, 0, 0, 0.5) 2.27%, rgba(35, 31, 32, 0.5) 109.27%), url("../../images/media_slider/media_back_orig.jpeg");
  background: linear-gradient(170.45deg, rgba(63, 86, 108, 0.7) -134.06%, rgba(0, 0, 0, 0.7) 2.27%, rgba(35, 31, 32, 0.7) 109.27%), url("../../images/media_slider/media_back_orig-min.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;

  text-align: center;

  padding: 5% 0;

  .section6_wrapper {
    //@extend .wrapper;
    .section6_slider {
      .mySwiper {
        :global(.swiper-pagination-bullet-active) {
          background-color: #fff;
        }

        :global(.swiper-wrapper) {
          align-items: center;
        }

        img {
          width: 50%;
          //max-width: 650px;
          //padding: 7% 0;
        }
      }
    }

  }
  @media (max-width: $mobile-screen) {
    background-size: 175%;
    padding: 7% 0;
    .section6_wrapper {
      .section6_slider {
        .mySwiper {
          :global(.swiper-pagination-bullet-active) {
            background-color: #fff;
          }

          :global(.swiper-wrapper) {
            align-items: center;
          }

          img {
            //width: 50%;
            width: 100%;
            max-width: 285px;
            padding: 7% 0;
          }
        }
      }
    }
  }
}