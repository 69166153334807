@import "../../Common.module.scss";

.section7 {
  div {
    position: relative;
    padding-top: 177.78%;
    background: center / contain no-repeat url(../../images/section7/section7thumbMobile.jpg);

    video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (min-width: 1000px) {
  .section7 {
    div {
      padding-top: 56.25%;
      background: none;

      video {
        object-fit: cover;
      }
    }
  }
}