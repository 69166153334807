@import "../../Common.module.scss";

.section3 {
  background: linear-gradient(170.62deg, #3F566C -23.44%, #5D384B 63.77%, #290A1D 141.42%);
  padding-top: 42px;
  padding-bottom: 65px;

  .section3_wrapper {
    @extend .wrapper;
    text-align: center;

    .rectangles_block {
      margin-top: 11px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    .circles_block {
      margin-top: 11px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div:nth-child(5) {
        margin-left: 13%;
      }
      div:nth-child(7) {
        margin-right: 13%;
      }
    }
  }

  @media (max-width: $extra-small-screen) {
    padding-top: 28px;
    padding-bottom: 44px;
  }

  @media (max-width: $mobile-screen) {
    .section3_wrapper {
      .rectangles_block {
        gap: 5px;
      }

      .circles_block {
        justify-content: center;
        margin-top: 35px;
        margin-left: 2%;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: (1fr, 1fr, 1fr ,0.5fr);
        //grid-gap: 10px;

        div:nth-child(n) {
          margin: 10px 0;
        }
        div:first-child {
          grid-row: 1 / span 2;
          grid-column: 1 / span 2;
        }
        div:nth-child(2) {
          grid-row: 3 / span 1;
          grid-column: 1 / span 1;
        }
        div:nth-child(odd):not(:first-child) {
          margin-top: -50%;
          margin-right: 10%;
        }
      }
    }
  }

  @media (max-width: 383px) {
    .section3_wrapper {
      //padding: 0 5px;
      .circles_block {
        margin-left: 1%;
      }

      .rectangles_block {
        gap: 2px;
      }
    }
  }
}

.rectangle_el {
  width: fit-content;
  max-width: 293px;

  text-align: center;

  h4 {
    font-size: 33px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 37px;
  }

  .rectangle {
    width: 152px;
    height: 126px;
    border: 2px solid #fff;
    border-radius: 20px;
    margin: 0 auto;
  }

  @media (max-width: $medium-screen) {
    max-width: 223px;

    h4 {
      font-size: 25px;
      margin-bottom: 28px;
    }
  }

  @media (max-width: $small-screen) {
    max-width: 169px;

    h4 {
      font-size: 19px;
      margin-bottom: 21px;
    }
  }

  @media (max-width: $extra-small-screen) {
    max-width: 85px;

    h4 {
      font-size: 9.5px;
      margin-bottom: 11px;
    }

    .rectangle {
      width: 81px;
      height: 67px;
    }
  }

  @media (max-width: $mobile-screen) {
    max-width: 80px;
    h4 {
      font-size: 9px;
    }
  }
}

.circle_el {
  //text-align: center;
  position: relative;

  width: 300px;
  height: 300px;
  border: 2px solid #fff;
  border-radius: 100%;
  //margin: 15px auto;
  margin-top: 15px;

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    margin: 0;

    white-space: pre;

    font-size: 27px;
    font-weight: 300;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.16);
  }

  @media (max-width: $medium-screen) {
    max-width: 225px;
    max-height: 225px;

    p {
      font-size: 21px;
    }
  }

  @media (max-width: $small-screen) {
    max-width: 170px;
    max-height: 170px;

    p {
      font-size: 17px;
    }
  }

  @media (max-width: $extra-small-screen) {
    max-width: 91px;
    max-height: 91px;

    p {
      font-size: 9px;
    }
  }

  @media (max-width: $mobile-screen) {
    max-width: 170px;
    max-height: 170px;

    p {
      font-size: 17px;
    }
  }
}