* {
  font-family: AvenirNext, Arial, sans-serif;
}
iframe {
  border: none;
}

.header {
  text-align: center;
  font-size: 48px;
  letter-spacing: 4px;
  margin: 0;
  padding: 48px;
  color: #fff;
  background: url(../images/login-bg.jpg) center / cover no-repeat;
}
.main {
  max-width: 1440px;
  padding: 48px;
  margin: 0 auto;
  margin-bottom: 96px;
  text-align: center;
}
.player {
  position: relative;

  &::before {
    float: left;
    padding-top: 56.25%;
    content: '';
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.videos {
  margin-top: 48px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;

  div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  div:hover {
    outline: 2px solid #000;
  }
  div:hover img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  img {
    border-radius: 4px;
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
  }
  h2 {
    font-weight: normal;
    font-size: 20px;
    flex-shrink: 0;
    margin: 0;
    margin-bottom: 4px;
  }
}

@media screen and (max-width: 640px) {
  .header {
    font-size: 32px;
    padding: 24px;
  }
  .main,
  .videos {
    margin: 0;
  }
  .videos {
    grid-template-columns: 1fr;

    div {
      position: relative;
    }
    div::before {
      float: left;
      padding-top: 56.25%;
      content: '';
    }
    div::after {
      display: block;
      content: '';
      clear: both;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    div:hover {
      outline: none;
    }
  }
}