@import "./Common.module.scss";

.App {
  padding: 0;
  margin: 0;
  font-family: AvenirNext, Arial, sans-serif;
  overflow-x: hidden;

  h2 {
    font-size: 77px;
    font-weight: 500;
    margin: 0;
    color: #fff;

    line-height: 141%;
    letter-spacing: 1px;

    @media (max-width: $medium-screen) {
      font-size: 58px;
    }
    @media (max-width: $small-screen) {
      font-size: 44px;
    }
    @media (max-width: $extra-small-screen) {
      font-size: 24px;
    }
  }

  @media screen and (max-width: $mobile-screen) {
    br {
      display: none;
    }
  }
}