@font-face {
  font-family: 'AvenirNext';
  font-weight: 400;
  src: url('https://pubstylescan.blob.core.windows.net/000001/fonts/AvenirNext-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'AvenirNext';
  font-weight: 500;
  src: url('https://pubstylescan.blob.core.windows.net/000001/fonts/AvenirNext-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'AvenirNext';
  font-weight: 700;
  src: url('https://pubstylescan.blob.core.windows.net/000001/fonts/AvenirNext-Bold.woff2') format('woff2');
}
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
