* {
  font-family: AvenirNext, Arial, sans-serif;
}

.header {
  padding: 84px;
  background: url(../images/login-bg.jpg) center / cover no-repeat;
}
.main {
  max-width: 1260px;
  margin: 0 auto;
  padding: 48px;
  padding-top: 24px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .svg {
    cursor: pointer;
    box-sizing: content-box;
    position: absolute;
    top: 56px;
    left: 0;
    padding: 8px;
    border-radius: 4px;
    stroke-width: 2px;
    transition: background-color 150ms;

    &:hover {
      background-color: #ccc;
    }
  }
  h1 {
    font-size: 36px;
    font-weight: 500;
    text-align: center;
  }

  .article {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 80ch;

    h2 {
      font-size: 32px;
    }
  }
  .more {
    margin-top: 24px;
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;

    img {
      aspect-ratio: 1;
      object-fit: cover;
      object-position: top;
    }
    // why larissa why
    div:nth-last-child(2) img {
      object-position: 0% 55%;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  div:hover {
    outline: 2px solid #000;
  }
  div:hover img {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  img {
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  h2 {
    font-weight: normal;
    font-size: 24px;
    flex-shrink: 0;
    margin: 0;
    padding: 0 12px;
    text-align: center;
  }
  h3 {
    font-weight: normal;
    font-size: 18px;
    flex-shrink: 0;
    margin: 0;
    padding: 0 12px;
    text-align: left;
  }
  p {
    flex-shrink: 0;
    margin: 0;
    padding: 0 12px;
    text-align: right;
  }
  p:first-of-type {
    margin-top: auto;
  }
  p:last-of-type {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 780px) {
  .header {
    padding: 36px;
  }
  .main {
    margin: 0;
    grid-template-columns: 1fr;
    padding: 16px;
    margin-bottom: 48px;

    .article {
      gap: 12px;

      h2 {
        font-size: 32px;
      }
    }
    .more {
      font-size: 24px;
      margin-top: 24px;
    }

    .svg {
      top: 24px;
      left: 24px;
    }
    .svg:hover {
      background-color: transparent;
    }

    div:hover {
      outline: none;
    }

    h2 {
      font-weight: normal;
      font-size: 32px;
      flex-shrink: 0;
      margin: 0;
      padding: 0 12px;
      text-align: center;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      flex-shrink: 0;
      margin: 0;
      padding: 0 12px;
      text-align: left;
    }
    p {
      font-size: 12px;
      flex-shrink: 0;
      margin: 0;
      margin-top: auto;
      margin-bottom: 12px;
      padding: 0 12px;
      text-align: right;
    }
  }
}