@import "../../Common.module.scss";

.section2 {
  position: relative;
  overflow: hidden;
  background-color: #fff;

  padding-top: 158px;
  padding-bottom: 86px;
  //padding-bottom: 93px;

  video:focus {
    outline: none;
  }

  .section2_headVid {
    position: absolute;
    //top: 37px;
    left: -250px;

    z-index: 1;
    object-fit: cover;
    display: block;
    max-height: 1015px;
    height: 100%;
    width: auto;

    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;

    @media (max-width: $medium-screen) {
      left: -190px;
    }
    @media (min-width: 1100px) and (max-width: 1175px) {
      left: -250px;
    }
    @media (min-width: 1000px) and (max-width: 1100px) {
      left: -330px;
    }
    @media (max-width: $small-screen) {
      left: -150px;
    }
    @media (min-width: 795px) and (max-width: 840px) {
      left: -190px;
    }
    @media (min-width: 780px) and (max-width: 795px) {
      left: -210px;
    }
    @media (max-width: $extra-small-screen) {
      left: -50px;
    }

    @media (max-width: $mobile-screen) {
      max-height: 600px;
      bottom: -1px;
      top: unset;
      left: 50%;
      transform: translateX(-47%);
    }

  }

  .section2_wrapper {
    @extend .wrapper;
    display: flex;
    justify-content: flex-end;
    //padding-right: 90px;

    position: relative;
    z-index: 99;

    text-align: right;

    .section2_content {
      max-width: 811px;
      text-align: right;

      h2 {
        color: #231F20;;
        text-align: right;
        white-space: nowrap;
        margin-bottom: 51px;
      }

      p {
        font-weight: 300;
        font-size: 26px;
        text-align: right;
        margin-bottom: 54px;

        //direction: rtl;

        @media screen and (max-width: $mobile-screen) {
          text-align: justify;
          text-align-last: center;
        }
      }

      .section2_content__images {
        margin-bottom: 63px;

        img {
          //width: 233px;
          height: 135px;
        }

        img:nth-child(n+2) {
          margin-left: 23px;
        }
      }

      h3 {
        font-weight: 500;
        font-size: 32px;
        margin-bottom: 51px;
      }

      .section2_content_btn {
        border: 1px solid #000;
        border-radius: 45px;

        font-size: 28px;
        color: #000;

        padding: 14px 56px;

        background-color: #fff;

        a {
          color: #000;
          text-decoration: none;
        }

        &:hover {
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.6);
          a {
            opacity: 0.6;
            transition: all 0.25s ease;
          }
        }
      }
    }
  }

  @media (max-width: $medium-screen) {
    padding-top: 118px;
    padding-bottom: 65px;

    .section2_wrapper {
      //padding-right: 98px;

      .section2_content {
        max-width: 617px;

        h2 {
          margin-bottom: 38px;
        }

        p {
          font-size: 20px;
          margin-bottom: 40px;
        }

        .section2_content__images {
          margin-bottom: 47px;

          img {
            //width: 233px;
            height: 103px;
          }
        }

        h3 {
          font-size: 24px;
          margin-bottom: 38px;
        }

        .section2_content_btn {
          font-size: 22px;
          padding: 11px 43px;
        }
      }
    }
  }

  @media (max-width: $small-screen) {
    padding-top: 88px;
    padding-bottom: 49px;

    .section2_wrapper {
      //padding-right: 37px;

      .section2_content {
        max-width: 473px;

        h2 {
          margin-bottom: 28px;
        }

        p {
          font-size: 15px;
          margin-bottom: 30px;
        }

        .section2_content__images {
          margin-bottom: 35px;

          img {
            //width: 233px;
            height: 78px;
          }
        }

        h3 {
          font-size: 18px;
          margin-bottom: 28px;
        }

        .section2_content_btn {
          font-size: 16px;
          padding: 8px 32px;
        }
      }
    }
  }

  @media (max-width: $extra-small-screen) {
    padding-top: 49px;
    padding-bottom: 29px;

    .section2_wrapper {
      //padding-right: 40px;

      .section2_content {
        max-width: 269px;

        h2 {
          margin-bottom: 21px;
        }

        p {
          font-size: 8px;
          margin-bottom: 17px;
        }

        .section2_content__images {
          margin-bottom: 22px;

          img {
            height: 40px;
          }
        }

        h3 {
          font-size: 10px;
          margin-bottom: 16px;
        }

        .section2_content_btn {
          font-size: 9px;
          padding: 5px 17px;
        }
      }
    }
  }

  @media (max-width: $mobile-screen) {
    padding: 1.5rem 15px 600px 15px;

    .section2_wrapper {
      padding: 0 5px;
      text-align: center;

      .section2_content {
        max-width: unset;
        margin: auto;

        h2 {
          margin-bottom: 21px;
          white-space: normal;
          text-align: center;
        }

        p {
          font-size: 1rem;
          margin-bottom: 17px;
          text-align-last: left;
        }

        .section2_content__images {
          margin-bottom: 22px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;

          img {
            height: 70px;
            //height: 100%;
            &:nth-child(n) {
              margin-left: 0;
            }
          }
        }

        h3 {
          display: none;
        }

        .section2_content_btn {
          display: none;
        }
      }
    }
  }
}
