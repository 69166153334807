@import "../../Common.module.scss";

.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background: #000;

  .footer_wrapper {
    @extend .wrapper;

    img {
      max-width: 260px;
      margin-bottom: 30px;
    }

    .boxes {
      display: flex;
      justify-content: space-between;

      a {
        color: #fff;
        text-decoration: none;
      }

      .footer_contactUs {
        h3 {
          font-size: 27px;
          color: #fff;
          font-weight: 300;
          margin: 0;
        }

        a {
          display: flex;
          align-items: center;
          font-size: 17px;
          margin-top: 10px;

          -webkit-transition: all 0.25s ease;
          -moz-transition: all 0.25s ease;
          -ms-transition: all 0.25s ease;
          -o-transition: all 0.25s ease;
          transition: all 0.25s ease;

          &:hover {
            opacity: 0.7;
          }
        }

        .footer_contactUs_svg {
          width: 30px;
          margin-right: 10px;
        }
      }

      .footer_links {
        a {
          font-size: 20px;
          text-align: right;
          margin-bottom: 15px;
          display: block;
          text-decoration: underline;

          &:hover {
            opacity: 0.7;
            transition: all 0.25s ease;
          }
        }
      }
    }
  }

  @media (max-width: $small-screen) {
    .footer_wrapper {
      padding-right: 0;
      padding-left: 0;
    }
  }
  @media (max-width: $extra-small-screen) {
    .footer_wrapper {
      text-align: center;

      .boxes {
        flex-direction: column;
        align-items: center;

        .footer_links {
          margin-top: 13px;
          a {
            text-align: center;
          }
        }
      }
    }
  }
}